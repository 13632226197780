<template>
    <section v-if="isLoggedIn" class="is-backend">
        <template>
            <navigation/>

            <div class="main-panel">
                <top-bar/>
                <ajax-error-warning/>

                <div class="content content-mobile-nav">
                    <router-view></router-view>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
  import Navigation from '@/app/layout/Navigation'
  import TopBar from '@/app/shared/global/template/TopBar'
  import AjaxErrorWarning from '@/app/shared/global/warnings/AjaxErrorWarning'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Backend',
    computed: mapGetters('auth', ['isLoggedIn']),
    components: {
      TopBar,
      AjaxErrorWarning,
      Navigation,
    },
  }
</script>
